import { FC, memo, useCallback } from 'react';

import { IMenuItem } from '$models';
import { key } from '~/utils';

type Props = {
    menuStructure?: IMenuItem[];
};

/**
 * Renders a hidden navigation structure for crawlers
 */
export const SeoNavigation: FC<Props> = memo(({ menuStructure }) => {
    const recursivelyRenderNavigation = useCallback(
        (menuItem: IMenuItem[]) =>
            menuItem.map((menuItem) => {
                return (
                    <li key={key(menuItem)}>
                        {menuItem?.children?.length ? (
                            <>
                                <a href={menuItem.url}>{menuItem.text}</a>
                                <ul>{recursivelyRenderNavigation(menuItem.children)}</ul>
                            </>
                        ) : (
                            <a href={menuItem.url}>{menuItem.text}</a>
                        )}
                    </li>
                );
            }),
        [menuStructure]
    );

    return <ul style={{ display: 'none' }}>{!!menuStructure && recursivelyRenderNavigation(menuStructure)}</ul>;
});
