import React, { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { INavigation } from '$models';
import { useScrollDown } from '~/hooks/use-scroll-down';
import { useSite } from '~/store/site';
import { MobileNavigation } from './components/mobile';
import { DesktopNavigation } from './components/desktop';
import { SeoNavigation } from './components/shared/seo-navigation';
import { Responsive } from '$components/elements/responsive';

export type MainNavigationProps = {
    menu: INavigation;
};

const variants = { visible: { transform: 'translateY(0%)' }, hidden: { transform: 'translateY(-100%)' } };

export const MainNavigation: FC<MainNavigationProps> = ({ menu }: MainNavigationProps) => {
    const scrollingDown = useScrollDown(150);
    const { headerHideable, inPageTransition, setSite } = useSite();

    useEffect(() => {
        // Make sure header is visible in page transitions
        const headerIsHideable = !inPageTransition;
        setSite({ headerHideable: headerIsHideable });
    }, [inPageTransition]);

    return (
        <StyledMainNavigation
            variants={variants}
            initial="visible"
            animate={scrollingDown && headerHideable ? 'hidden' : 'visible'}
            transition={{ ease: 'easeOut' }}
        >
            <Responsive max="frame">
                <MobileNavigation menu={menu} />
            </Responsive>
            <Responsive min="frame">
                <DesktopNavigation menu={menu} />
            </Responsive>

            <SeoNavigation menuStructure={menu?.mainMenuItems} />
        </StyledMainNavigation>
    );
};

const StyledMainNavigation = styled(motion.header)(({ theme }) => ({
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: theme.zIndices.navigation,
    background: theme.colors.white,
}));
