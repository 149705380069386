import { useEffect, useState } from 'react';
import { useViewportScroll } from 'framer-motion';

export const useScrollDown = (offset = 0) => {
    const [scrollDown, setScrollDown] = useState(false);
    const { scrollY } = useViewportScroll();

    const update = () => {
        const currentY = scrollY.get();
        const previousY = scrollY.getPrevious();

        if (currentY >= offset && currentY > previousY) {
            setScrollDown(true);
        } else if (currentY < previousY) {
            setScrollDown(false);
        }
    };

    useEffect(() => {
        const unsubscribe = scrollY.onChange(update);
        return () => {
            unsubscribe();
        };
    }, []);

    return scrollDown;
};
